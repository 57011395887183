import React from 'react'
import { Card } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import * as styles from "../../pages/dashboard/dashboard.module.css"

const SkeletonVerticalCard = (props) => {
    return (
        <>{
            props.purpose == "card" &&

            <Card className={styles.carouselCard} style={{ borderRadius: "25px", boxShadow: "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)" }}>
                <div className={styles.favorite} >
                    <Skeleton style={{ borderRadius: "25px 25px 0 0", height: "275px" }} />
                </div><div className="row m-0">
                    <div style={{ height: "110px" }}>
                        <Skeleton style={{ height: "20px", width: "100%", marginTop: "23px" }} />
                        <Skeleton style={{ height: "20px", width: "100%", marginTop: "19px" }} />
                    </div></div></Card>
        }
            {props.purpose == "agent" && <Card className={styles.agentCarouselCard2}

                style={{
                    borderRadius: "25px",
                    width: props.details !== undefined && props.details == "agent-detail" ? "74%" : "94%",
                    height: props.details !== undefined && props.details == "agent-detail" ? "480px" : "480px",
                    boxShadow:
                        "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)",
                }}
            ><div className={styles.favorite} style={{ textAlign: "center", paddingTop: "20px" }}>
                    <Skeleton style={{ height: "130px", width: "130px", borderRadius: "50%" }} /> </div>
                <Skeleton style={{ height: "18px", width: "90%", margin: "19px" }} />
                <Skeleton style={{ height: "18px", width: "90%", margin: "19px" }} />
                <Skeleton style={{ height: "18px", width: "90%", margin: "19px" }} />
                <Skeleton style={{ height: "18px", width: "90%", margin: "19px" }} />
                <Skeleton style={{ height: "50px", width: "52%", margin: "13px 99px" }} /></Card>}

            {props.purpose == "agentClaim" && <Card className={styles.agentCarouselCard2}

                style={{
                    borderRadius: "25px",
                    width: props.details !== undefined && props.details == "agent-detail" ? "100%" : "94%",
                    // height: props.details!==undefined && props.details=="agent-detail"?"480px":"480px",
                    boxShadow:
                        "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)",
                    flexDirection: "row", alignItems: "center"
                }}
            ><div className={styles.favorite} style={{ textAlign: "center", padding: "20px 0 20px 20px" }}>
                    <Skeleton style={{ height: "86px", width: "85px", borderRadius: "21%" }} /> </div>
                <div style={{ width: "64%" }} > <Skeleton style={{ height: "18px", width: "90%", margin: "19px" }} /></div>
                {/* <Skeleton style={{ height: "18px", width: "90%", margin: "19px" }} />
                <Skeleton style={{ height: "18px", width: "90%", margin: "19px" }} /> */}
                {/* <Skeleton style={{ height: "18px", width: "90%", margin: "19px" }} /> */}
                <div style={{ width: "12%" }}> <Skeleton style={{ height: "50px", width: "10%%", }} /></div></Card>}

            {props.purpose == "notification" &&
                <Card className='notifyCard' >
                    <div className={styles.favorite} style={{ padding: "20px 20px 0 20px" }}>
                        <div className='image-wrap'>
                            <Skeleton className='image-notify' style={{ height: "80px", width: "80px", borderRadius: "50%" }} />
                            <Skeleton style={{ height: "20px", width: "92%", marginTop: "7px", marginLeft: "16px" }} />
                        </div>
                        <Skeleton style={{ height: "20px", width: "100%", marginTop: "23px" }} />
                        <Skeleton style={{ height: "42px", width: "36%", marginTop: "32px", marginLeft: "63%" }} /></div>

                </Card>

            }
            {props.purpose == "PropsImg" &&
                <div className="col-md-12" ><Skeleton className="detailhome1"></Skeleton> </div>
            }
        </>
    )
}

export default SkeletonVerticalCard
// height: 42px;
//     width: 36%;
//     margin-top: 32px;
//     margin-left: 197px;